<template>
  <div class="container-xl">
    <div class="card col-lg-12">
      <div class="card-header justify-content-between">
        <div class="card-title">
          <span class="mr-2">{{ $route.name }} List</span>
        </div>
      </div>
      <div class="card-body">
        <div class="flex">
          <div class="w-1/3">
            <el-form ref="form" label-width="150px">
              <el-form-item label="Filter Type">
                <el-select @change="filter.search_keyword = ''" size="small" v-model="filter.search_by" class="mr-2 w-full">
                  <el-option v-for="(item, index) in search_options" :key="index" :value="item.value" :label="item.text"/>
                </el-select>
              </el-form-item>
              <el-form-item v-if="filter.search_by === 'company_id'" label="Company">
                <el-select @change="searchHandler" filterable remote :remote-method="getCompanyRequestor" size="small" v-model="filter.search_keyword" class="mr-2 w-full" placeholder="Select company">
                  <el-option v-for="(item, index) in company_list" :key="index" :value="item.value" :label="item.text"/>
                </el-select>
              </el-form-item>
              <el-form-item v-else label="MyBenefit Package">
                <el-select @change="searchHandler" size="small" v-model="filter.search_keyword" class="mr-2 w-full" placeholder="Select package">
                  <el-option v-for="(item, index) in package_list" :key="index" :value="item.value" :label="item.text"/>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <div class="w-1/3">
            <el-form ref="form" label-width="150px">
              <el-form-item label="Approval Status">
                <el-select @change="searchHandler" size="small" v-model="filter.status_filter" class="mr-2 w-full">
                  <el-option v-for="(item, index) in status_options" :key="index" :value="item.value" :label="item.text"/>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-button type="info" @click="searchHandler" size="small"><font-awesome-icon icon="search"/> Search</el-button>
      </div>
      <div class="table-responsive" v-loading="loading.data">
        <table class="table card-table text-nowrap table-vcenter">
          <thead>
            <tr>
              <th>Request Date</th>
              <th>Requestor Name</th>
              <th>Company Name</th>
              <th>Package</th>
              <th>Effective Date</th>
              <th>Approval Status</th>
              <th>Approval Date</th>
              <th>Approval Name</th>
              <th v-if="canApproveMyBen">#</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in list" :key="index">
              <td>{{ item.created_at_str }}</td>
              <td>{{ item.user_requestor ? item.user_requestor.name : 'System' }}</td>
              <td>{{ item.company.company_name }}</td>
              <td>
                <div class="row" v-if="item.mybenefit_package">
                  <div class="col-lg-4">
                    <img :src="item.mybenefit_package.package_logo"/>
                  </div>
                  <div class="col">
                    <div class="font-weight-medium">
                      {{ item.mb_package }}
                    </div>
                  </div>
                </div>
              </td>
              <td>{{ item.effective_at_str }}</td>
              <td>
                <span class="tag" :class="item.status_color">{{ item.status_str }}</span>
              </td>
              <td>{{ item.approval_at_str }}</td>
              <td>{{ item.user_approval ? item.user_approval.name : '-' }}</td>
              <td v-if="canApproveMyBen">
                <el-button size="mini" :disabled="item.status != 'request'" type="success" round @click="handlerBtnApproval(item, 'approve')">
                  <font-awesome-icon icon="check"/>
                </el-button>
                <el-button size="mini" :disabled="item.status != 'request'" type="danger" round @click="handlerBtnApproval(item, 'reject')">
                  <font-awesome-icon icon="times"/>
                </el-button>
              </td>
            </tr>
          </tbody>
        </table>
        <el-empty v-if="total_rows == 0" description="No data found" :image="emptyStateImage"></el-empty>
      </div>
      <pagination
        :page="page"
        :per_page="per_page"
        :list="list"
        :total_rows="total_rows"
        @pageChange="pageChangeHandler"/>
    </div>
  </div>
</template>
<script>
import { uniqBy } from 'lodash';
import moment from 'moment';
import {
  GET_MYBENEFIT_REQUEST, APPROVAL_MYBENEFIT_REQUEST, GET_MYBENEFIT_REQUEST_COMPANY, GET_MYBENEFIT,
} from '@/store/modules/mybenefit';
import emptyStateImage from '@/assets/images/undraw/empty-approval-history.svg';
import Pagination from '../../components/Pagination.vue';
import popupErrorMessages from '../../../library/popup-error-messages';

export default {
  components: { Pagination },
  name: 'MyBenefitRequest',
  metaInfo: {
    title: 'MyBenefit Request',
  },
  data() {
    return {
      loading: {
        data: false,
      },
      emptyStateImage,
      total_rows: 0,
      list: [],
      page: 1,
      per_page: 20,
      filter: {
        search_by: 'company_id',
        search_keyword: '',
        status_filter: '',
      },
      search_options: [{
        text: 'Company',
        value: 'company_id',
      }, {
        text: 'MyBenefit Package',
        value: 'mybenefit_package_id',
      }],
      company_list: [{
        value: '', text: 'All',
      }],
      package_list: [{
        value: '', text: 'All',
      }],
      status_options: [
        { value: '', text: 'All' },
        { value: 'request', text: 'Waiting Approval' },
        { value: 'approved', text: 'Approved' },
        { value: 'rejected', text: 'Rejected' },
      ],
      timeout: {
        filter_company: null,
      },
    };
  },
  async mounted() {
    const loader = this.$loading.show();
    await this.getList();
    this.getCompanyRequestor();
    this.loadMyBenefitList();
    loader.hide();
  },
  computed: {
    currentMyProfile() {
      return this.$store.getters.currentMyProfile;
    },
    currentRole() {
      return this.$store.getters.current_role;
    },
    canApproveMyBen() {
      return this.currentRole.page_name === '*' || this.currentMyProfile.is_checker;
    },
  },
  methods: {
    async getList() {
      const status = {
        approved: {
          text: 'Approved',
          color: 'tag-success',
        },
        request: {
          text: 'Waiting Approval',
          color: 'tag-warning',
        },
        rejected: {
          text: 'Rejected',
          color: 'tag-danger',
        },
      };
      this.loading.data = true;
      if (this.filter.search_keyword === '' && this.filter.search_by === 'company_id') {
        this.getCompanyRequestor();
      }
      const { count, rows } = await this.$store.dispatch(GET_MYBENEFIT_REQUEST, {
        page: this.page,
        ...this.filter,
      });
      this.loading.data = false;
      this.total_rows = count;
      this.list = rows.map((v) => {
        v.status_str = status[v.status]?.text || 'Unknown';
        v.status_color = status[v.status]?.color || 'tag-secondary';
        v.mb_package = v.mybenefit_package ? v.mybenefit_package.package_name : '-';
        v.mb_package_color = `tag-${v.mb_package.toLowerCase()}`;
        v.created_at_str = moment(v.created_at).format('DD MMM YYYY, HH:mm');
        v.effective_at_str = moment(v.mybenefit_start_at).format('DD MMM YYYY');
        v.approval_at_str = '-';
        if (v.status === 'approved') v.approval_at_str = moment(v.approved_at).format('DD MMM YYYY, HH:mm');
        if (v.status === 'rejected') v.approval_at_str = moment(v.rejected_at).format('DD MMM YYYY, HH:mm');
        return v;
      });
    },
    pageChangeHandler(value) {
      this.page = value;
      this.getList();
    },
    handlerBtnApproval(item, act) {
      let msg = 'Are you sure want to approve this request? Package will active based on effective date.';
      if (act === 'reject') {
        msg = 'Are you sure want to reject this request?';
      }
      this.$confirm(msg, 'Confirmation', {
        type: 'info',
        confirmButtonText: 'Yes',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            try {
              await this.$store.dispatch(APPROVAL_MYBENEFIT_REQUEST, {
                id: item.id,
                action: act,
              }).then(() => {
                this.$message({
                  title: 'Success',
                  type: 'success',
                  message: 'Approval success',
                });
                this.getList();
                done();
              }).catch((err) => {
                popupErrorMessages(err.response.data);
              });
              instance.confirmButtonLoading = false;
            } catch (err) {
              console.log(err);
            }
            instance.confirmButtonLoading = false;
          }
          done();
        },
      });
    },
    async getCompanyRequestor(value = '') {
      clearTimeout(this.timeout.filter_company);
      this.timeout.filter_company = setTimeout(async () => {
        const { rows } = await this.$store.dispatch(GET_MYBENEFIT_REQUEST_COMPANY, {
          search_by: 'company_name',
          search_keyword: value,
        });
        this.company_list = this.company_list.filter((v) => v.value === '' || v.value === this.filter.search_keyword);
        this.company_list.push(...rows.map((v) => ({ value: v.id, text: v.company_name })));
        this.company_list = uniqBy(this.company_list, 'value');
      }, 1000);
    },
    async loadMyBenefitList() {
      this.loading.package_list = true;
      await this.$store.dispatch(GET_MYBENEFIT).then((response) => {
        this.package_list.splice(1, this.package_list.length);
        this.package_list.push(...response.rows.map((v) => ({ value: v.id, text: v.package_name })));
      });
      this.loading.package_list = false;
    },
    searchHandler() {
      this.page = 1;
      this.getCompanyRequestor();
      this.getList();
    },
  },
};
</script>
<style>
.tag-gold {
  background-color: #fcc200;
  color: #fff;
  font-weight: 600;
}
.tag-bronze {
  background-color: #CD7F32;
  color: #fff;
  font-weight: 600;
}
</style>
